import { ActionContext } from 'vuex';
import { api } from '@/api';
import { State } from '../state';
import { AxiosError } from 'axios';
import router from '@/router';
import { getLocalToken, removeLocalToken, saveLocalToken } from '@/utils';


import { AppNotification, MainState } from '../main/state';
import { getStoreAccessors } from 'typesafe-vuex';
import {
    commitAddNotification,
    commitRemoveNotification,
    commitSetLoggedIn,
    commitSetLogInError,
    commitSetToken,
} from '../main/mutations';

type MainContext = ActionContext<MainState, State>;


export const actions = {
    async actionLogOut(context: MainContext) {
        await dispatchRemoveLogIn(context);
        await dispatchRouteLogOut(context);
    },
    async actionRemoveLogIn(context: MainContext) {
        removeLocalToken();
        commitSetToken(context, '');
        commitSetLoggedIn(context, false);
    },
    actionRouteLogOut(context: MainContext) {
        if (router.currentRoute.path !== '/login') {
            router.push('/login');
        }
    },
    async actionCheckApiError(context: MainContext, payload: AxiosError) {
        if (payload.response!.status === 401) {
            await dispatchLogOut(context);
        }
        commitAddNotification(context, { color: 'error', content: payload.response!.data.detail });
    },
    async readAppsForUser(context: MainContext) {
        try {
            const response = await api.getAppsForUser(context.rootState.main.token);
            return response?.data;
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async readAppByCode(context: MainContext, AppCode: string) {
        try {
            const response = await api.getAppByCode(context.rootState.main.token, AppCode);
            return response?.data;
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    // updateAppByCode
    async actionUpdateAppByCode(context: MainContext, payload) {
        try {
            const response = await api.updateAppByCode(context.rootState.main.token, payload.AppCode, payload);
            return response?.data;
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },

    async actionCreateApp(context: MainContext, payload) {
        try {
            const loadingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = await api.createAppsForUser(context.rootState.main.token, payload);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'User successfully created', color: 'success' });
            return response?.data;
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },

    async readTransactionsForUser(context: MainContext) {
        try {
            const response = await api.getTransactionsForUser(context.rootState.main.token);
            return response?.data;
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },

    async readServiceProviders(context: MainContext) {
        try {
            const response = await api.getServiceProviders(context.rootState.main.token);
            return response?.data;
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateServiceProvider(context: MainContext, payload) {
        try {
            const loadingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = await api.updateServiceProviders(context.state.token, payload.id, payload);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Service prodvider successfully updated', color: 'success' });
            return response?.data;

        } catch (error) {
            await dispatchCheckApiError(context, error);
        }

    },

    async actionCreateServiceProvider(context: MainContext, payload) {
        try {
            const loadingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = await api.createServiceProviders(context.rootState.main.token, payload);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Service provider successfully created', color: 'success' });
            return response?.data;
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },

    async readServiceProviderById(context: MainContext, id) {
        try {
            const loadingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = await api.getServiceProviderById(context.rootState.main.token, id);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Get service provider successfull', color: 'success' });
            return response?.data;
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    }
};

const { dispatch } = getStoreAccessors<MainState | any, State>('');

export const dispatchCheckApiError = dispatch(actions.actionCheckApiError);
export const dispatchLogOut = dispatch(actions.actionLogOut);
export const dispatchRemoveLogIn = dispatch(actions.actionRemoveLogIn);
export const dispatchRouteLogOut = dispatch(actions.actionRouteLogOut);

export const dispatchreadAppsForUser = dispatch(actions.readAppsForUser);
export const dispatchcreateAppForUser = dispatch(actions.actionCreateApp);
export const dispatchreadAppByCode = dispatch(actions.readAppByCode);

export const dispatchreadTransactionsForUser = dispatch(actions.readTransactionsForUser);

export const dispatchreadServiceProviders = dispatch(actions.readServiceProviders);
export const dispatchCreateServiceProvider = dispatch(actions.actionCreateServiceProvider);
export const dispatchGetServiceProviderById = dispatch(actions.readServiceProviderById);
export const dispatchUpdateServiceProviderById = dispatch(actions.actionUpdateServiceProvider);
export const dispatchUpdateAppByCode = dispatch(actions.actionUpdateAppByCode);


