import axios from 'axios';
import { apiUrl } from '@/env';
import { IUserProfile, IUserProfileUpdate, IUserProfileCreate } from './interfaces';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export const api = {
  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);

    return axios.post(`${apiUrl}/api/v1/login/access-token`, params);
  },
  async getMe(token: string) {
    return axios.get<IUserProfile>(`${apiUrl}/api/v1/users/me`, authHeaders(token));
  },
  async updateMe(token: string, data: IUserProfileUpdate) {
    return axios.put<IUserProfile>(`${apiUrl}/api/v1/users/me`, data, authHeaders(token));
  },
  async getUsers(token: string) {
    return axios.get<IUserProfile[]>(`${apiUrl}/api/v1/users/`, authHeaders(token));
  },
  async updateUser(token: string, userId: number, data: IUserProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/users/${userId}`, data, authHeaders(token));
  },
  async createUser(token: string, data: IUserProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/users/`, data, authHeaders(token));
  },
  async createUserPublic(data: IUserProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/users/createuserpublic`, data);
  },
  async passwordRecovery(email: string) {
    return axios.post(`${apiUrl}/api/v1/password-recovery/${email}`);
  },
  async resetPassword(password: string, token: string) {
    return axios.post(`${apiUrl}/api/v1/reset-password/`, {
      new_password: password,
      token,
    });
  },

  async getAppsForUser(token: string) {
    return axios.get(`${apiUrl}/api/v1/applications/`, authHeaders(token));
  },

  async getAppByCode(token: string, AppCode: string) {
    return axios.get(`${apiUrl}/api/v1/applications/${AppCode}`, authHeaders(token));
  },
  async updateAppByCode(token: string, AppCode: string, data) {
    return axios.put(`${apiUrl}/api/v1/applications/${AppCode}`, data, authHeaders(token));
  },
  async createAppsForUser(token: string, payload) {
    // const config = {
    //   headers: { Authorization: `Bearer ${token}`},
    //   params: payload,
    // };
    // console.log("************** config ***********");
    // console.log(config);
    return axios.post(`${apiUrl}/api/v1/applications/`, payload, authHeaders(token));
  },

  async getTransactionsForUser(token: string) {
    return axios.get(`${apiUrl}/api/v1/transactions/`, authHeaders(token));
  },

  async getServiceProviders(token: string) {
    return axios.get(`${apiUrl}/api/v1/serviceproviders/all`, authHeaders(token));
  },
  async createServiceProviders(token: string, payload) {
    return axios.post(`${apiUrl}/api/v1/serviceproviders/`, payload, authHeaders(token));
  },
  async getServiceProviderById(token: string, id: number) {
    return axios.get(`${apiUrl}/api/v1/serviceproviders/${id}`, authHeaders(token));
  },
  async updateServiceProviders(token: string,id:number, data) {
    return axios.put(`${apiUrl}/api/v1/serviceproviders/${id}`, data, authHeaders(token));
  },
};
